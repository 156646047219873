<script>
import { layoutMethods } from "@/state/helpers";
import { menuItems } from "./horizontal-menu";
import {
  authComputed
} from "@/state/helpers";

export default {
  data() {
    return {
      menuItems: process.env.VUE_APP_VH === 'yes' ? menuItems.map(x => {
        if(x.id === 3000) {
          return {
            id: 3000,
            label: 'Resources',
        
            routes:[
                'tutorial'
            ],
            subItems: [
                {
                    id: 3500,
                    label: 'pages.tutorial.menuItem-title',
                    to:{ name: 'tutorial'},
                    routes:[
                        'tutorial'
                    ]
                },
                {
                    id: 3600,
                    label: 'Knowledge Hub',
                    to:'https://vikingharbour.com/#knowledgehub',
                    external: true,
                    routes:[
                        'tutorial'
                    ]
                }   
            ]
          };
        } else {
          return x;
        }
      }) : menuItems
    };
  },
  mounted() {
    var links = document.getElementsByClassName("side-nav-link-ref");
    var matchingMenuItem = null;
    for (var i = 0; i < links.length; i++) {
      if (window.location.pathname === links[i].pathname) {
        matchingMenuItem = links[i];
        break;
      }
    }
    if (matchingMenuItem) {
      matchingMenuItem.classList.add("active");
      var parent = matchingMenuItem.parentElement;

      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.add("active");
        const parent2 = parent.parentElement;
        if (parent2) {
          parent2.classList.add("active");
        }
        const parent3 = parent2.parentElement;
        if (parent3) {
          parent3.classList.add("active");
          var childAnchor = parent3.querySelector(".has-dropdown");
          if (childAnchor) childAnchor.classList.add("active");
        }

        const parent4 = parent3.parentElement;
        if (parent4) parent4.classList.add("active");
        const parent5 = parent4.parentElement;
        if (parent5) parent5.classList.add("active");
      }
    }
  },
  computed: {
    ...authComputed,
    filteredMenuItems() {
      
      if(this.isFxHedge) {
        const isAdmin = this.$store.state.auth.currentUser.isAdmin;
        if(!isAdmin) return [];
        return  [
            {
                id: 1,
                label: 'Home',
                // icon: 'fa fa-home',
                to:{ name: 'home'},
                routes:[
                    'home'
                ]
            },
            {
                id: 2001,
                label: 'pages.administration.users.menuItem-title',
                role: ['administrator'],
                to:{ name: 'users' }
              
            },
          ]
        ;
      } else {
        return this.menuItems.filter(a => this.hasAccess(a)).map(x => {
          return {...x, subItems: (x.subItems || []).filter(a => this.hasAccess(a))};
        });
      }
    },
    isFxHedge() {
      return process.env.VUE_APP_HEDGE === 'yes';
    },
  },
  methods: {
    ...layoutMethods,
    isActive(item){
      if(item.routes && item.routes.find(routeName => routeName === this.$route.name)){
        return true;
      }
    },
    hasAccess(item) {
      if(item.role) {
        if(this.$store.state.auth && this.$store.state.auth.currentUser) {
          const roles = this.$store.state.auth.currentUser.roles;
          if(roles.find(a => item.role.find(s => s === a))){
           
            return true;
          }
        }
        return false;
      }
      return true;
    },
    /**
     * Menu clicked show the submenu
     */
    onMenuClick(event) {
      event.preventDefault();
      const nextEl = event.target.nextSibling;
      if (nextEl && !nextEl.classList.contains("show")) {
        const parentEl = event.target.parentNode;
        if (parentEl) {
          parentEl.classList.remove("show");
        }
        nextEl.classList.add("show");
      } else if (nextEl) {
        nextEl.classList.remove("show");
      }
      return false;
    },
    /**
     * Returns true or false if given menu item has child or not
     * @param item menuItem
     */
    hasItems(item) {
      return item.subItems !== undefined ? item.subItems.length > 0 : false;
    },
    change_layout(layout) {
      return this.changeLayoutType({ layoutType: layout });
    },
    topbarLight() {
      document.body.setAttribute("data-topbar", "light");
      document.body.removeAttribute("data-layout-size", "boxed");
    },
    boxedWidth() {
      document.body.setAttribute("data-layout-size", "boxed");
      document.body.removeAttribute("data-topbar", "light");
      document.body.setAttribute("data-topbar", "dark");
    }
  }
};
</script>

<template>
  <div class="topnav" v-if="!isFxHedge">
    <div class="">
      <nav class="navbar navbar-light navbar-expand-lg topnav-menu">
        <div class="collapse navbar-collapse" id="topnav-menu-content">
          <ul class="navbar-nav">
            <li class="nav-item dropdown" v-for="(item, index) of filteredMenuItems" :key="index">
              <router-link
                tag="a"
                v-if="!item.subItems || item.subItems.length === 0"
                :to="item.to || item.link"
                class="nav-link dropdown-toggle arrow-none"
                :class="{'active': isActive(item)}"
              >
                <i :class="`${item.icon} mr-2`"></i>
                {{$t(item.label)}}
              </router-link>

              <a
                v-if="item.subItems && item.subItems.length > 0"
                class="nav-link dropdown-toggle arrow-none"
                @click="onMenuClick"
                href="javascript: void(0);"
                id="topnav-components"
                role="button"
              >
                <i :class="`${item.icon} mr-2`"></i>
                {{$t(item.label)}}
                <div class="arrow-down"></div>
              </a>
              <div
                class="dropdown-menu row"
                aria-labelledby="topnav-dashboard"
                v-if="hasItems(item)"
              >
                <template v-for="(subitem) of item.subItems">
                
                  <router-link
                    :key="subitem.id"
                    class="col dropdown-item side-nav-link-ref"
                    v-if="!hasItems(subitem) && !subitem.external"
                    :to="subitem.to || subitem.link"
                  >{{$t(subitem.label)}}</router-link>
                   <a
                    :key="subitem.id"
                    class="col dropdown-item side-nav-link-ref"
                    v-if="!hasItems(subitem) && subitem.external"
                    :href="subitem.to || subitem.link"
                  >{{$t(subitem.label)}}</a>
                  <div class="dropdown" v-if="hasItems(subitem)" :key="subitem.id">
                    <a class="dropdown-item" href="javascript: void(0);" @click="onMenuClick">
                      {{ $t(subitem.label) }}
                      <div class="arrow-down"></div>
                    </a>
                    <div class="dropdown-menu">
                      <template  v-for="(subSubitem, index) of subitem.subItems">
                        {{subSubitem}}
                         <router-link
                          v-if="!subSubitem.external"
                          :key="index*-1"
                          :to="subSubitem.to || subSubitem.link"
                          class="dropdown-item side-nav-link-ref"
                        >{{ $t(subSubitem.label) }}</router-link>
                         <a
                           v-if="subSubitem.external"
                          :key="index*-1"
                          :href="subSubitem.to"
                          class="dropdown-item side-nav-link-ref"
                        >{{ $t(subSubitem.label) }}</a>
                      </template>
                     
                    </div>
                  </div>
                </template>
              </div>
            </li>
          
          </ul>
        </div>
      </nav>
    </div>
  </div>
  <div class="topnav" v-else-if="filteredMenuItems.length > 0">
    <div class="">
      <nav class="navbar navbar-light navbar-expand-lg topnav-menu">
        <div class="collapse navbar-collapse" id="topnav-menu-content">
          <ul class="navbar-nav">
            <li class="nav-item dropdown" v-for="(item, index) of filteredMenuItems" :key="index">
              <router-link
                tag="a"
                v-if="!item.subItems || item.subItems.length === 0"
                :to="item.to || item.link"
                class="nav-link dropdown-toggle arrow-none"
                :class="{'active': isActive(item)}"
              >
                <i :class="`${item.icon} mr-2`"></i>
                {{$t(item.label)}}
              </router-link>

              <a
                v-if="item.subItems && item.subItems.length > 0"
                class="nav-link dropdown-toggle arrow-none"
                @click="onMenuClick"
                href="javascript: void(0);"
                id="topnav-components"
                role="button"
              >
                <i :class="`${item.icon} mr-2`"></i>
                {{$t(item.label)}}
                <div class="arrow-down"></div>
              </a>
              <div
                class="dropdown-menu row"
                aria-labelledby="topnav-dashboard"
                v-if="hasItems(item)"
              >
                <template v-for="(subitem) of item.subItems">
                
                  <router-link
                    :key="subitem.id"
                    class="col dropdown-item side-nav-link-ref"
                    v-if="!hasItems(subitem) && !subitem.external"
                    :to="subitem.to || subitem.link"
                  >{{$t(subitem.label)}}</router-link>
                   <a
                    :key="subitem.id"
                    class="col dropdown-item side-nav-link-ref"
                    v-if="!hasItems(subitem) && subitem.external"
                    :href="subitem.to || subitem.link"
                  >{{$t(subitem.label)}}</a>
                  <div class="dropdown" v-if="hasItems(subitem)" :key="subitem.id">
                    <a class="dropdown-item" href="javascript: void(0);" @click="onMenuClick">
                      {{ $t(subitem.label) }}
                      <div class="arrow-down"></div>
                    </a>
                    <div class="dropdown-menu">
                      <template  v-for="(subSubitem, index) of subitem.subItems">
                        {{subSubitem}}
                         <router-link
                          v-if="!subSubitem.external"
                          :key="index*-1"
                          :to="subSubitem.to || subSubitem.link"
                          class="dropdown-item side-nav-link-ref"
                        >{{ $t(subSubitem.label) }}</router-link>
                         <a
                           v-if="subSubitem.external"
                          :key="index*-1"
                          :href="subSubitem.to"
                          class="dropdown-item side-nav-link-ref"
                        >{{ $t(subSubitem.label) }}</a>
                      </template>
                     
                    </div>
                  </div>
                </template>
              </div>
            </li>
          
          </ul>
        </div>
      </nav>
    </div>
  </div>
</template>